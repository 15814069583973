import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  View,
  Platform,
  Pressable,
  FlatList,
  findNodeHandle,
  DeviceEventEmitter,
  NativeEventEmitter,
  StyleSheet,
  Text,
} from 'react-native';
import * as Device from 'expo-device';
import { Button } from 'react-native-paper';
import { useFocusEffect, useLinkTo } from '@react-navigation/native';
import { SheetManager } from 'react-native-actions-sheet';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  clearPassportOffers,
  getCollectibleDetails,
  loadAvailableEventsList,
  loadAvailableOffersList,
  loadPassportOfferList,
  loadThirpartyCollectibleList,
} from '../../slices/OfferSlice';
import styles from '../../styles';
import {
  resetSearchPassport,
  setPassportSearchText,
  updateSelectedPassport,
  updateEntirePassportDetail,
} from '../../slices/PassportSlice';
import AppLoaderComponent from '../Loader';
import { defaultTheme } from '../../themes/loot8';
import {
  getNetwork,
  getStaticProvider,
  getAppConfiguration,
  getAnynetStaticProvider,
  PASSPORT_CACHE_LAST_FETCH,
} from '../../appconstants';
import { wait } from '../../helpers/ipfs';
import HOCContainer from '../HOCContainer';
import ExpassDetailsCard from '../ExpassDetailsCard';
import { CatalogTypes } from '../../enums/catalog.enum';
import {
  readMessages,
  getSocialMediaAccess,
  resetPassportMessage,
  setinitialMessageLoading,
  subscribeToPassportMessages,
} from '../../slices/PassportMessageSlice';
import Collectibles from './Tabs/Collectibles';
import FeaturedOffers from './Tabs/FeaturedOffers';
import GradientBorderBox from '../GradientBorderBox';
import DirectMessaging from './Tabs/DirectMessaging';
import AnimatedAccordian from '../AnimatedAccordian';
import MapParameterItem from '../Map/MapParameterItem';
import useIsResponsive from '../../hooks/useIsResponsive';
import { getSyncedTime } from '../../helpers/DateHelper';
import CommunityDiscussion from './Tabs/CommunityDiscussion';
import { getData, storeData } from '../../helpers/AppStorage';
import { getMaxSocialLinksForUI } from '../../helpers/Gadgets';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import { PassportType } from '../../enums/passportCategory.enum';
import { getCollectionConstraint } from '../../helpers/MapHelper';
import { CustomDeviceEvents, ScreenName } from '../../enums/screen.enum';
import { ICollectibleDetail } from '../../interfaces/ICollectibleDetail.interface';
import Events from './Tabs/Events';
import { useCollectibleMint } from '../../hooks/useCollectibleMint';
import { CollectibleWebBlurView } from '../WebBlurView';
import { BlurView } from 'expo-blur';
import SectionHeading from '../SectionHeading';

const isWeb = Platform.OS === 'web';

const PassportDetail = ({ navigation, route }) => {
  const pathConfig = isWeb
    ? {
        path: window.location.pathname,
        queryParams: new URLSearchParams(window.location.href.split('?')[1]),
      }
    : null;
  const { passportAddress, chainId } =
    pathConfig &&
    pathConfig.queryParams.get('passportAddress') &&
    pathConfig.queryParams.get('passportAddress') !== ''
      ? {
          passportAddress: pathConfig.queryParams.get('passportAddress'),
          chainId: +pathConfig.queryParams.get('chainId'),
        }
      : route.params;

  const { mintLinkedCollectibles } = useCollectibleMint();

  const {
    address,
    wallet,
    networkId,
    staticProvider,
    clearPassPort,
    updatePassPort,
  } = useWeb3AuthContext();
  const { width: activeWidth } = useActiveDimensions();

  const entityData = useAppSelector(state => state.Entity.EntityData);
  const allPassportDetailsList = useAppSelector(
    state => state.Passports.AllPassportDetailsList,
  );
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const dispatch = useAppDispatch();
  const tabsRef = useRef(null);
  const isResponsive = useIsResponsive();

  const [isPublicVisit, setIsPublicVisit] = useState(false);
  const scrollView = useRef<KeyboardAwareScrollView>();
  const [activeTab, setActiveTab] = useState(0);
  const [yCoordinate, setYCoordinate] = useState(200);
  const [socialLinksList, setSocialLinksList] = useState([]);
  const [selectPassport, setSelectPassport] = useState<ICollectibleDetail>();
  const [scrollToMessages, setScrollToMessages] = useState(false);
  const [isSocialAvailable, setSocialAvailable] = useState(true);

  const boxWidth = isResponsive
    ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH / 6
    : activeWidth / 5;

  const linkTo = useLinkTo();

  useEffect(() => {
    dispatch(setinitialMessageLoading(true));
    return () => {};
  }, [route?.params]);

  const loadSocialMediaMessages = async selectedPassport => {
    if (isPublicVisit) {
      return;
    }
    dispatch(setinitialMessageLoading(true));
    await getSocialMediaAccess(
      selectedPassport.address,
      selectedPassport.chainId,
    ).then(isSocialAvailable => {
      setSocialAvailable(isSocialAvailable);

      if (isSocialAvailable) {
        dispatch(
          readMessages({
            address: selectedPassport.address,
            chainId: networkId,
            networkID: networkId,
            provider: staticProvider,
            wallet,
          }),
        ).then(() => {
          dispatch(
            subscribeToPassportMessages({
              address: selectedPassport.address,
              chainId: networkId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          );
          dispatch(setinitialMessageLoading(false));
        });
      }
    });
  };

  const loadPassportDetails = async selectedPassport => {
    if (isPublicVisit) {
      return;
    }

    if (selectedPassport && selectedPassport?.address) {
      setSocialLinksList(getMaxSocialLinksForUI(selectedPassport));
      // Removing this line since Passport reward balance is not used anywhere
      // dispatch(loadPassportRewardRate({ networkID: networkId, provider: staticProvider, address, passport: selectedPassport, wallet }));
      dispatch(
        loadPassportOfferList({
          networkID: networkId,
          provider: staticProvider,
          passport: selectedPassport,
          wallet,
          address,
        }),
      ).then(() => {
        // load offers
        dispatch(
          loadAvailableOffersList({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            passport: selectedPassport,
            userLocation: currenLocation,
          }),
        );
        // load events
        dispatch(
          loadAvailableEventsList({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            passport: selectedPassport,
            userLocation: currenLocation,
          }),
        );
      });
      dispatch(
        loadThirpartyCollectibleList({
          networkID: networkId,
          provider: staticProvider,
          passport: selectedPassport,
          wallet,
          address,
        }),
      );
      dispatch(resetPassportMessage());
      loadSocialMediaMessages(selectedPassport);

      const lastRefresh = await getData(
        PASSPORT_CACHE_LAST_FETCH(passportAddress),
      );
      const config = await getAppConfiguration();
      if (!lastRefresh) {
        await storeData(
          PASSPORT_CACHE_LAST_FETCH(passportAddress),
          getSyncedTime(),
        );
      } else if (
        getSyncedTime() - Number(lastRefresh) >
        (config?.refreshPassportCacheFrequency || 5 * 60 * 1000)
      ) {
        getCollectibleDetails(
          {
            networkID: chainId,
            provider: getAnynetStaticProvider(chainId),
            collectibleAddress: passportAddress,
            address,
            wallet,
          },
          { entityData },
          { isCache: false },
        ).then(passport => {
          dispatch(updateEntirePassportDetail(passport));
        });
        await storeData(
          PASSPORT_CACHE_LAST_FETCH(passportAddress),
          getSyncedTime(),
        );
      }
    }
  };

  const init = async () => {
    let passport = allPassportDetailsList.find(
      p => p.address == passportAddress,
    );
    if (!passport) {
      const providerToPass = getAnynetStaticProvider(chainId);
      passport = await getCollectibleDetails(
        {
          networkID: chainId,
          provider: providerToPass,
          collectibleAddress: passportAddress,
          address,
          wallet,
        },
        { entityData },
        {},
      );
    }
    if (
      !passport?.collectibleIds?.length ||
      passport?.collectibleIds?.length == 0
    ) {
      setIsPublicVisit(true);
    } else {
      setIsPublicVisit(false);
    }

    setSelectPassport(passport);

    if (passport.collectibleIds?.length > 0) {
      updatePassPort(passport);
      dispatch(updateSelectedPassport(passport));
      mintLinkedCollectibles(passport).then(res => {
        if (res) loadPassportDetails(passport);
      });
    }
    // load passport details
    loadPassportDetails(passport);
  };

  useFocusEffect(
    useCallback(() => {
      if (passportAddress) init();
    }, [passportAddress]),
  );

  useEffect(() => {
    let event: any = DeviceEventEmitter;
    if (Platform.OS === 'android') {
      event = new NativeEventEmitter();
    }
    event.addListener(CustomDeviceEvents.HomeTabPressedAgain, () => {
      clearPassportSelected();
    });
    return () =>
      event.removeAllListeners(CustomDeviceEvents.HomeTabPressedAgain);
  }, [navigation]);

  const clearPassportSelected = async function () {
    clearPassPort();
    dispatch(clearPassportOffers());
    dispatch(updateSelectedPassport(null));
    dispatch(resetSearchPassport());
    dispatch(setPassportSearchText(''));
    await dispatch(resetPassportMessage());
    if (Platform.OS != 'web' && Device.isDevice) {
      linkTo('/home');
    } else {
      if (Platform.OS === 'web') {
        window.history.replaceState(null, '', '/');
      }
      navigation.reset({
        index: 0,
        routes: [
          { name: 'HomeTab', params: { screen: 'HomeScreen', path: 'home' } },
        ],
      });
    }
  };

  const scrollToText = async event => {
    if (Platform.OS !== 'web') {
      const node = findNodeHandle(event.target);
      await wait(50);
      scrollView.current.scrollToFocusedInput(node, 290);
    } else {
      if (
        event &&
        event?.nativeEvent?.layout?.top +
          event?.nativeEvent?.layout?.height +
          90 >
          window.innerHeight
      ) {
        scrollView.current.scrollToFocusedInput(
          findNodeHandle(event.nativeEvent.target),
          0,
          100,
        );
      }
    }
  };

  const onTabPress = index => {
    if (activeTab !== index) {
      setActiveTab(index);
    }
    const offset = isResponsive
      ? index *
        (defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
          defaultTheme.HORIZONTAL_PADDING * 2)
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);

    tabsRef?.current.scrollToOffset({ offset });
  };

  const onCreatePost = () => {
    navigation.navigate('CreatePostScreen', {
      passportAddress: selectPassport?.address,
      chainId: networkId,
      setScrollToMessages: setScrollToMessages,
    });
  };

  return (
    <>
      <KeyboardAwareScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 150,
        }}
        ref={scrollView}
        enableOnAndroid={false}
        resetScrollToCoords={{ x: 0, y: yCoordinate }}
        enableResetScrollToCoords={false}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        viewIsInsideTabBar={true}
        enableAutomaticScroll
        extraHeight={170}
        extraScrollHeight={120}
        keyboardOpeningTime={0}>
        <ExpassDetailsCard
          selectPassport={selectPassport}
          socialLinksList={socialLinksList}
          isPublicVisit={isPublicVisit}
        />
        <View style={{ marginVertical: 12.5 }} />
        {/* <AnimatedAccordian /> */}
        <FlatList
          horizontal
          data={tabsData}
          renderItem={({ item, index }) => (
            <GradientBorderBox
              label
              selectable
              item={item}
              index={index}
              numLinesForLabel={2}
              activeTab={activeTab}
              width={boxWidth}
              height={isResponsive ? 62 : 44}
              onPress={() => onTabPress(index)}
            />
          )}
          showsVerticalScrollIndicator={false}
          keyExtractor={item => item.id.toString()}
          style={{ flexGrow: 0, marginTop: 25, marginBottom: 16 }}
          contentContainerStyle={{
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
          ItemSeparatorComponent={() => (
            <View style={{ marginHorizontal: 5 }} />
          )}
        />

        <FlatList
          horizontal
          ref={tabsRef}
          data={tabsData}
          style={{ flex: 1 }}
          scrollEnabled={false}
          snapToAlignment="start"
          decelerationRate={'fast'}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          renderItem={({ index }) => (
            <Slide
              activeIndex={index}
              selectPassport={selectPassport}
              passportAddress={passportAddress}
              chainId={chainId}
              scrollToMessages={scrollToMessages}
              setScrollToMessages={setScrollToMessages}
              isSocialAvailable={isSocialAvailable}
              scrollToText={scrollToText}
              scrollView={scrollView}
              isPublicVisit={isPublicVisit}
            />
          )}
          snapToInterval={activeWidth - defaultTheme.HORIZONTAL_PADDING * 2}
        />
      </KeyboardAwareScrollView>
      {activeTab === 2 && (
        <Pressable
          style={[
            styles.fabIcon,
            {
              width: 46,
              height: 46,
              borderWidth: 2,
              borderRadius: 100,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              borderColor: isSocialAvailable
                ? defaultTheme.GRADIENT_COLOR1
                : defaultTheme.PRIMARY_SHADOW_COLOR,
              right: 0,
              top: '65%',
            },
          ]}
          disabled={!isSocialAvailable}
          onPress={isSocialAvailable && onCreatePost}>
          <Icon
            name="chat-plus-outline"
            size={26}
            color={
              isSocialAvailable
                ? defaultTheme.PRIMARY_TEXT_COLOR
                : defaultTheme.PRIMARY_SHADOW_COLOR
            }
            onPress={isSocialAvailable && onCreatePost}
          />
        </Pressable>
      )}
    </>
  );
};

const PassportConstraints = ({ navigation, item, address }) => {
  const activePassportDetailsList = useAppSelector(
    state => state.Passports.AllPassportDetailsList,
  );
  const loadingPassport = useAppSelector(state => state.Passports.loading);
  const [constaintDataLoaded, setConstaintDataLoaded] = useState(false);
  const [constraintItem, setConstraintItem] = useState();

  useEffect(() => {
    (async () => {
      const constraintList = await getCollectionConstraint(
        address,
        activePassportDetailsList,
        [],
        item,
        getStaticProvider(),
        getNetwork(),
      );
      setConstraintItem({
        ...item,
        id: 0,
        coordinate: {
          latitude: Number(item?.area[0][0]),
          longitude: Number(item?.area[0][1]),
        },
        title: item.name,
        type: item.collectionType,
        constraintList,
        enableImage: false,
      });
      setConstaintDataLoaded(true);
    })();
  }, [loadingPassport]);

  const goToSubscribeSheet = async () => {
    if (item?.passportType == PassportType.SUBSCRIPTION) {
      await SheetManager.show('SubscribePassportSheet', {
        payload: {
          hideSubscribePassportsModal: () => {},
          selectedEXPass: item,
          navigation,
          resetToHome: true,
          setSearchPassportText: null,
          collectionType: CatalogTypes.PASSPORT,
        },
      });
    } else {
      //safe side
      goToHomePage();
    }
  };

  const goToHomePage = () => {
    if (Platform.OS === 'web') {
      window.history.replaceState(null, '', '/');
    }
    navigation.reset({
      index: 0,
      routes: [
        { name: 'HomeTab', params: { screen: 'HomeScreen', params: {} } },
      ],
    });
  };

  return !constaintDataLoaded ? (
    <AppLoaderComponent />
  ) : (
    <View>
      <MapParameterItem
        item={constraintItem}
        itemIndex={0}
        customMessage={
          'You do not possess the ExPass. Please meet the following requirements in order to obtain the ExPass.'
        }
      />
      {
        <View
          style={{
            marginTop: 15,
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}>
          {item?.passportType == PassportType.SUBSCRIPTION && (
            <Button
              onPress={() => {
                goToSubscribeSheet();
              }}
              style={{
                height: 40,
                borderRadius: 6,
                width: '45%',
                backgroundColor: '#F4B540',
                justifyContent: 'center',
              }}
              labelStyle={[
                styles.passportActionSuccessModalButtonText,
                { fontSize: defaultTheme.FONT_SIZE_XXMEDIUM },
              ]}>
              {'Subscribe'}
            </Button>
          )}
          <Button
            onPress={() => {
              goToHomePage();
            }}
            style={{
              height: 40,
              borderRadius: 6,
              width: '45%',
              backgroundColor: '#F4B540',
              justifyContent: 'center',
            }}
            labelStyle={[
              styles.passportActionSuccessModalButtonText,
              { fontSize: defaultTheme.FONT_SIZE_XXMEDIUM },
            ]}>
            {'Go to ExPass List'}
          </Button>
        </View>
      }
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: PassportDetail,
  title: 'ExPass',
  isScrollEnabled: false,
});

const Slide = ({
  activeIndex,
  selectPassport,
  passportAddress,
  chainId,
  scrollToMessages,
  setScrollToMessages,
  isSocialAvailable,
  scrollToText,
  scrollView,
  isPublicVisit = false,
}) => {
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Collectibles"
              description="Unlock your free collectibles by subscribing this Experience Passport"
            />
          );
        }
        return <Collectibles selectPassport={selectPassport} />;
      case 1:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Featured Offers"
              description="Unlock Featured Offers by subscribing this Experience Passport"
            />
          );
        }
        return <FeaturedOffers />;
      case 2:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Community Discussion"
              description="Unlock Community Discussions by subscribing this Experience Passport"
            />
          );
        }
        return (
          <CommunityDiscussion
            selectPassport={selectPassport}
            passportAddress={passportAddress}
            chainId={chainId}
            scrollToMessages={scrollToMessages}
            setScrollToMessages={setScrollToMessages}
            isSocialAvailable={isSocialAvailable}
            scrollToText={scrollToText}
            scrollView={scrollView}
          />
        );
      case 3:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Direct Messaging"
              description="Unlock the Direct Messaging feature by subscribing this Experience Passport"
            />
          );
        }
        return <DirectMessaging selectPassport={selectPassport} />;
      case 4:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Events"
              description="Unlock Events by subscribing this Experience Passport"
            />
          );
        }
        return <Events selectPassport={selectPassport} />;
      default:
        if (isPublicVisit) {
          return (
            <LockedBlurView
              title="Direct Messaging"
              description="Unlock the Direct Messaging feature by subscribing this Experience Passport"
            />
          );
        }
        return <DirectMessaging selectPassport={selectPassport} />;
    }
  };

  return (
    <View
      style={
        isResponsive
          ? {
              width:
                defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                defaultTheme.HORIZONTAL_PADDING * 2,
            }
          : {
              width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
            }
      }>
      {renderContent()}
    </View>
  );
};

const tabsData = [
  {
    id: 1,
    name: 'Collectibles',
  },
  {
    id: 2,
    name: `Featured Offers`,
  },
  {
    id: 3,
    name: `Community Discussion`,
  },
  {
    id: 4,
    name: `Direct Messaging`,
  },
  {
    id: 5,
    name: `Events`,
  },
];

const LockedBlurView = ({ title = '', description = '' }) => {
  return (
    <>
      <SectionHeading title={title} />

      <View style={{ height: 10 }} />
      {Platform.OS === 'web' ? (
        <CollectibleWebBlurView>
          <View style={blurStyle.blurView}>
            <Icon
              name="lock-outline"
              size={26}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
            <Text style={blurStyle.blurText}>{description}</Text>
          </View>
        </CollectibleWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={blurStyle.blurView}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <Icon
            name="lock-outline"
            size={26}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
          <Text style={blurStyle.blurText}>{description}</Text>
        </BlurView>
      )}
    </>
  );
};

const blurStyle = StyleSheet.create({
  blurView: {
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.2)',
    minHeight: 170,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurText: {
    marginTop: 10,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
  },
});
