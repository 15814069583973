import arbitrum_logo from './assets/svg/arbitrum_plain.svg';
import ethereum_logo from './assets/svg/ethereum_plain.svg';
import polygon_matic from './assets/svg/polygon_matic.svg';

import {
  StaticJsonRpcProvider,
  EtherscanProvider,
} from '@ethersproject/providers';
import Constants from 'expo-constants';
import { LogToLoot8Console } from './helpers/Loot8ConsoleLogger';
import * as FileSystem from 'expo-file-system';
import { Category } from './enums/category.enum';
import { AppEnvironment } from './enums/env.enum';

let _env = Constants.expoConfig.extra.ENVIRONMENT;

let _ipfs_url = Constants.expoConfig.extra.IPFS_URL;
let _ipns_url = Constants.expoConfig.extra.IPNS_URL;
let _pubsub_url = Constants.expoConfig.extra.PUBSUB_URL;
let _api_request_timeout = Constants.expoConfig.extra.API_REQUEST_TIMEOUT;
let _app_configuration = null;
let _relayerInfo = null;
let _maintenanceInfo = {
  inMaintenance: false,
  maintenanceInfo: '',
};
let _serverTimeOffset = null;

export const refreshURLs = async () => {
  try {
    const ReqSentTimeStamp = Date.now();
    const response = await fetch(
      Constants.expoConfig.extra.IPNS_UPLOAD_LAMBDA_URL + 'status',
      { method: 'POST' },
    );
    const ResReceivedTimestamp = Date.now();
    if (response.status === 200) {
      const res = await response.json();
      _ipfs_url = res.ipfsGateway + '/';
      _ipns_url = res.ipfsGateway + '/ipns/';
      _pubsub_url = res.ipfsAPI.replace('/api/v0', '');
      if (res.timestamp) {
        //in case client clock is not in sync with server clock, store the clock skew (offset/diff) value
        const offset = Math.round(
          res.timestamp -
            ReqSentTimeStamp -
            (ResReceivedTimestamp - ReqSentTimeStamp) / 2,
        );
        //If client clock is not in sync with server clock for more then 15 seconds then need to consider clock skew for API requests
        if (
          offset &&
          offset !== 0 &&
          Math.round(Math.abs(offset / 1000)) > 15
        ) {
          _serverTimeOffset = offset;
        }
      }
      _maintenanceInfo = {
        inMaintenance: res.inMaintenance,
        maintenanceInfo: res.maintenanceInfo,
      };
    }
  } catch (error) {
    console.error('RefreshURL Error: ', error);
  }
};

export const GetDefaultLocation: any = {
  Lat: 40.718848,
  Long: -74.012298,
};

export enum NetworkId {
  INVALID_CHAIN = 0,
  ARBITRUM = 42161,
  POLYGON_MAINNET = 137,
  MAINNET = 1,
  ARBITRUM_SEPOLIA = 421614,
  ETHEREUM_SEPOLIA = 11155111,
  ARBITRUM_NOVA = 42170,
  STAGING_ARBITRUM_NOVA = 42170999,
}
export const getUTCTime = function () {
  return new Date().getTime();
};

export const timeout = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getMaintenanceInfo = () => _maintenanceInfo;

export const getClockSkew = () => _serverTimeOffset;

export const IPFS_URL = () => {
  return _ipfs_url;
};

export const EST_PORTAL_API_REQUEST_TIMEOUT = () => {
  return _api_request_timeout;
};

export const EST_PORTAL_WEBHOOK_URL = (networkId: number | string) => {
  if (networkId && _relayerInfo) {
    const relayer = _relayerInfo?.find(x => x.networkId === networkId);
    if (relayer && relayer.url) {
      return relayer.url;
    }
  }
  return NETWORKS[networkId].WEBHOOK_URL;
};
export const OFFRAMP_LAMBDA_URL_PROD = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_PROD;
};

export const OFFRAMP_LAMBDA_URL_DEV = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_DEV;
};

export const OFFRAMP_LAMBDA_URL_STAGING = () => {
  return Constants.expoConfig.extra.OFFRAMP_LAMBDA_URL_STAGING;
};

export const TXHISTORY_LAMBDA_URL_DEV = () => {
  return Constants.expoConfig.extra.TRANSACTION_HISTORY_URL_DEV;
};

export const IPNS_UPLOAD_LAMBDA_URL =
  Constants.expoConfig.extra.IPNS_UPLOAD_LAMBDA_URL;
export const MARKET_PLACE_LAMBDA_URL =
  Constants.expoConfig.extra.MARKET_PLACE_LAMBDA_URL;
export const RESPONSIVE = Constants.expoConfig.extra.RESPONSIVE;
export const RESPONSIVE_WIDTH = '1024';

export const SOCIAL_MEDIA_API =
  Constants.expoConfig.extra.SOCIAL_MEDIA_API || IPNS_UPLOAD_LAMBDA_URL;
export const BATCHIFY_LAMBDA_URL =
  Constants.expoConfig.extra.BATCHIFY_LAMBDA_URL;
export const SMALL_IMAGE_SIZE_LIMIT = 4 * 1024 * 1024; // 4 MB

export const IPNS_URL = () => {
  return _ipns_url;
};
export const FRIENDS_IPNS_FILE_NAME = 'friends.json';
export const USERDETAIL_IPNS_FILE_NAME = 'userdetail.json';
export const PUBSUB_URL = () => {
  return _pubsub_url;
};
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export enum ToastCustomMessageType {
  INFO,
  ERROR,
}
interface IAddresses {
  [key: number]: { [key: string]: string };
}

// Define fallback addresses used across all environments,
// Empty to prevent major exceptions for e.g addresses[invalid_chain].CollectionManager
const FALLBACK_ADDRESSES = {
  CollectionFactory: '',
  DAOAuthority: '',
  EntityRegistry: '',
  CollectibleFactory: '',
  PrivateForwarder: '',
  User: '',
  OrderDispatcher: '',
  DAOERC20: '',
  CollectionManager: '',
  ExternalCollectionManager: '',
  CollectionHelper: '',
  TokenPriceCalculator: '',
  RelayerAddress: '',
  DispatcherHelper: '',
  Loot8SignatureVerification: '',
  SubscriptionManager: '',
  Loot8Token: '',
  MarketPlace: '',
  USDC: '',
  Onboarder: '',
  LayerZeroEndPoint: '',
};

// Define chain addresses for each environment and chain
const ADDRESSES_BY_ENV = {
  [AppEnvironment.DEVELOPMENT]: {
    [NetworkId.ARBITRUM_SEPOLIA]: {
      CollectionFactory: '0x63Cc63B2AE79AE58EacFc54D03a7496C88D9643C',
      DAOAuthority: '0x9d2a5AE0289C57DEf7390B723cFB03ffc55380D5',
      EntityRegistry: '0x5fbaBE8E17B1eC0ADF4AB1ec6a360cAD87E18d6F',
      CollectibleFactory: '0x63Cc63B2AE79AE58EacFc54D03a7496C88D9643C',
      PrivateForwarder: '0x0fC89252F0994870eD41af3dE2EEaFBAc69D7063',
      Loot8Forwarder: '0xF7932eF5c52b6fDae09EB557480C4CceA3D9888e', // New Forwarder Contract
      User: '0x51b8EEC06ae1A04fF8843674ECb946F5C736C502',
      OrderDispatcher: '0x071047E8A0f2eb1891a78A89459Afe9850B45d84',
      DAOERC20: '0xA0109145440922d326ad6Ba96e74F4778DB0B128',
      CollectionManager: '0x33e5a1FEc542d8b7E829521FBf514f197937b322',
      ExternalCollectionManager: '0x4e59CB2A460C801887C2D8082102642466c67471',
      CollectionHelper: '0x0f2ffdEACe3Df90f7b128DC7D9B3ff3b7342F219',
      TokenPriceCalculator: '0x97D8970F1046745e4814f373Ceb8C1d4eD50705F',
      RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1',
      DispatcherHelper: '0x673238Af161AAf7FbD87fAC4904461c22C544B38',
      Loot8SignatureVerification: '0x04aFA5DCB84D74c624DAEa75CD419605797db50a',
      SubscriptionManager: '0x76318bd75D56525c7B9728F12C58D37c4F893F3c',
      Loot8Token: '0xA0109145440922d326ad6Ba96e74F4778DB0B128',
      MarketPlace: '0x7a39Db6E4088081424A79aa06E9D4adcf388b689',
      OffRamp: '0x449630150176B3f664700C8360d67F4fE07B4097',
      Onboarder: '0x353D1D35cdCaF2DA5C75B39c8f154A51DF138096',
      LayerZeroEndPoint: '0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1',
      EventRegistry: '0xa1F20515631040824f5017Ed9bB13e84076Ae67c',
      Loot8TicketVerification: '0x637d896a3EF2147A26389530Ab26C41712F1FA26',
    },
    [NetworkId.ETHEREUM_SEPOLIA]: {
      CollectionFactory: '0x8F8B1D413A9a2ae920508217C0a250060FdD1b65',
      DAOAuthority: '0x6818Ee1cC5a6F8DE5cf4E87C83cF394E863F13ad',
      EntityRegistry: '0x86dB18fc4148B189b54838Bf9d1413601FbDeE49',
      CollectibleFactory: '0x8F8B1D413A9a2ae920508217C0a250060FdD1b65',
      PrivateForwarder: '0x5aD478855321Ccd2bDc45793628355fb4421aBc2',
      Loot8Forwarder: '0x36749824ED31c9AF0C8CCBb7830C3dc4919FDDcB',
      User: '0x8e6DcCf7dE4FC296E65f42Fd9910Ab6C42D33226',
      OrderDispatcher: '0xC044189572aa3207621522F0f56eb590D7249dE9',
      DAOERC20: '0xF95d7589D976dB3E9E17FCF5D01A8f22afc8D973',
      CollectionManager: '0x36075D788bDcb543d1AF8A4d47a8d8F2BE108821',
      ExternalCollectionManager: '0x09d7Ff15fBb8F893A411D187c69587cA01e52799',
      CollectionHelper: '0x334e121b9f193D57dc252E0ABD708FeB9196d2a6',
      TokenPriceCalculator: '0x2a5ab8D7909Ce4d2749A3E5CBe45D8b3DF4600B7',
      RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1', // same as arb sepolia
      DispatcherHelper: '0xb410F55F4E2A8B6eFd81C3625C41D9a5f10338F9',
      Loot8SignatureVerification: '0x67F0eE5e5E9E27E72E7d923AbA669720e371Aa76',
      SubscriptionManager: '0x8e00621333E81eAb1e50201c00755003aE2f67E0',
      Loot8Token: '0xF95d7589D976dB3E9E17FCF5D01A8f22afc8D973',
      MarketPlace: '0x54B9F392f1aE688Fb321DEcA84530AC9265B0a43',
      OffRamp: '0xbF51B0A509680300b9327D1ac87A6a61a6BA6523',
      Onboarder: '0xaAA49884AFE0032BC8b1d40cdDB17bD627B6F233',
      LayerZeroEndPoint: '0x6EDCE65403992e310A62460808c4b910D972f10f',
      EventRegistry: '0x0A13Bee4cE1FFa87896D5e5AF1F27F8916D14cde',
      Loot8TicketVerification: '0x85Bb783DAaD51B0455d4b63a039266dB4196287E',
    },
  },
  [AppEnvironment.STAGING]: {
    [NetworkId.STAGING_ARBITRUM_NOVA]: {
      CollectionFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
      DAOAuthority: '0xEff619A93B05FBD746777C86C74f08FC0FA97D36',
      EntityRegistry: '0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8',
      CollectibleFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
      PrivateForwarder: '0x086AB3dD6F57018df7A84818e676c121b3a3aBDd',
      User: '0xF4C57821b217C7891e820D98688002c6E5983a02',
      OrderDispatcher: '0x8F39506f9466019F2121fc8b6624AD00153D7F64',
      DAOERC20: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
      CollectionManager: '0x7D405530f6de57ae99C0Aef6d7251c404589e25C',
      ExternalCollectionManager: '0xBFceF9ed665b97723a4a102FDCfF85214611e396',
      CollectionHelper: '0x1F75698fA087F77Bd02D2636317EA12F4A74a081',
      TokenPriceCalculator: '0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07',
      RelayerAddress: '0x80cC5Cc40aF77054D132869C1655661D7bE06ab1',
      DispatcherHelper: '0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0',
      Loot8SignatureVerification: '0xEBbD640C8d4E7aD239F65bD59B901F408DA0eD5a',
      SubscriptionManager: '0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6',
      Loot8Token: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
      MarketPlace: '0x7634F7315b055A6a39e7f0D55f54BE255f913783',
      OffRamp: '0x053eFFD341e69592d9e65431aF829779f70E0A89',
      LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      Onboarder: '0xe4cb440c4502e17FF4247977956564d8D3D5c6b6',
      EventRegistry: '0x20b8704Bd1678b3250e42616D6F05791030b1201',
      Loot8TicketVerification: '0x5804504Dd781937103a9fC76fb00724A28308B8d',
    },
  },
  [AppEnvironment.PRODUCTION]: {
    [NetworkId.ARBITRUM]: {
      CollectionFactory: '0x488F1e04529d74F20f6F4a6Ea82C2798F81288C9',
      DAOAuthority: '0xEb66fA49EC9607A4e2dE5172235D675352E54521',
      EntityRegistry: '0x103d356C104048F3F7098dd504b4766f8AdeB59D',
      CollectibleFactory: '0x488F1e04529d74F20f6F4a6Ea82C2798F81288C9',
      PrivateForwarder: '0xA0C19aaa2ac7a6e5bF2012F0D66Ea9a266A0788F',
      Loot8Forwarder: '0xA618602A01E0B545046Bfadee6A0B55d1552B00C',
      User: '0xb5CD39a04576E53EEC4ba13BeaC7f5c5c01ebF67',
      OrderDispatcher: '0xa239412E8973549EC1F6B64bC0cEc63ccD450271',
      DAOERC20: '0xa01598Ced2777958c3Ab1834648771bc67695482',
      CollectionManager: '0x457CA63331523f0966f65B1fBa04a64B8D643837',
      ExternalCollectionManager: '0xF0E5fE892442CA6acA31D99408bb5Aba196EBC5c',
      CollectionHelper: '0x08b3Ce83c462347c2CbF64375334cE4762Fc790F',
      TokenPriceCalculator: '0x91e1Ecdd503802a0a4C198371072940322492b06',
      RelayerAddress: '0xe283A51c57b36Eb462F284d02b6988bfccb2f5B7',
      DispatcherHelper: '0xA8D455bb1F193858D3f5B2481C0f42B3F35C241B',
      Loot8SignatureVerification: '0x57D9319De0427736a44215037E90e42B057BaA48',
      SubscriptionManager: '0x54b0b9504bc5DAac7f59186c678D99A4A71716BB',
      Loot8Token: '0xa01598Ced2777958c3Ab1834648771bc67695482',
      MarketPlace: '0x4b5d0A3BCF3a22f7cF533585A269d470C1afB4a2',
      USDC: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      Onboarder: '0x0F9d7e42bA01B675Fc94849B29b2e21a73F61B70',
      LayerZeroEndPoint: '0x1a44076050125825900e736c501f859c50fE728c',
      OffRamp: '0xf4F8Ff01c1A1a8d33642AcB1c254f6EfC67f8b15',
      Loot8TicketVerification: '0xCae9F5f24540Fb397eC768C19596B6AB942adA91',
      EventRegistry: '0xc90Bc9e8B9a200506c9479DF0573b07Ff6bDC69f',
    },
    [NetworkId.POLYGON_MAINNET]: {
      CollectionFactory: '0xCC9601EE5dF9D612fE7E508f222c2b345b1a304d',
      PrivateForwarder: '0xe0542a0ab39EB36E45a001735f952881AD44511F',
      RelayerAddress: '0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA',
      USDC: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      MarketPlace: '0xC1E58dc55DacADc7eCE25172C5A631eA94a0cD2A',
      LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
    },
    [NetworkId.ARBITRUM_NOVA]: {
      CollectionFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
      DAOAuthority: '0xEff619A93B05FBD746777C86C74f08FC0FA97D36',
      EntityRegistry: '0xcEFF1F19F6F3B9dA3bce9363D1908dD892dAa9B8',
      CollectibleFactory: '0x15b9024F3d36F5Ad6323ef397e9D082E9B03E24C',
      PrivateForwarder: '0x086AB3dD6F57018df7A84818e676c121b3a3aBDd',
      User: '0xF4C57821b217C7891e820D98688002c6E5983a02',
      OrderDispatcher: '0x8F39506f9466019F2121fc8b6624AD00153D7F64',
      DAOERC20: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
      CollectionManager: '0x7D405530f6de57ae99C0Aef6d7251c404589e25C',
      ExternalCollectionManager: '0xBFceF9ed665b97723a4a102FDCfF85214611e396',
      CollectionHelper: '0x1F75698fA087F77Bd02D2636317EA12F4A74a081',
      TokenPriceCalculator: '0xC91FA4b1d811a58D0952F6C4181d5262cBaE1C07',
      RelayerAddress: '0x2b7388cD2B8Cc25C91368a56F59c07c3FE46DfcA',
      DispatcherHelper: '0xD3810ebeEfa414a7f14992001623F9B8d47bB1b0',
      Loot8SignatureVerification: '0xEBbD640C8d4E7aD239F65bD59B901F408DA0eD5a',
      SubscriptionManager: '0x5a782aD9aC18A8d3Db6C98c30ee8f20Ab8Bb00a6',
      Loot8Token: '0xECbCF497Fb0321e2E0CEfa560F3B5ace2cF99b17',
      MarketPlace: '0x7634F7315b055A6a39e7f0D55f54BE255f913783',
      OffRamp: '0x053eFFD341e69592d9e65431aF829779f70E0A89',
      Onboarder: '0xe4cb440c4502e17FF4247977956564d8D3D5c6b6',
      LayerZeroEndPoint: '0x3c2269811836af69497E5F486A85D7316753cf62',
      Loot8TicketVerification: '0xDb2B506BB32eE6c9cA415630f3bA87767f39f4a8',
      EventRegistry: '0x29a2e3b9e4e01B411C1c5E35F67bb8C1E45589Ee',
    },
  },
};

export const addresses: IAddresses = new Proxy(
  Object.keys(ADDRESSES_BY_ENV[_env] || {}).reduce(
    (acc, chainId) => ({
      ...acc,
      [chainId]: {
        ...FALLBACK_ADDRESSES,
        ...ADDRESSES_BY_ENV[_env][chainId], // Override with specific addresses for the current environment
      },
    }),
    {},
  ),
  {
    get(target, chainId: string) {
      return target[chainId] || FALLBACK_ADDRESSES;
    },
  },
);

/**
 * Network details required to add a network to a user's wallet, as defined in EIP-3085 (https://eips.ethereum.org/EIPS/eip-3085)
 */

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals?: number;
}

interface INetwork {
  chainName: string;
  chainId: number;
  nativeCurrency: INativeCurrency;
  rpcUrls: string[];
  blockExplorerUrl: string;
  image: any; //SVGImageElement;
  imageAltText: string;
  uri: () => string;
  WEBHOOK_URL: string;
  STAGING_WEBHOOK_URL?: string;
  genesisBlockNumber: number;
}

interface ISubgraphConfigModules {
  user: boolean;
  userOwnedCollections: boolean;
  whitelistedCollections: boolean;
  entity: boolean;
  listTokenOwner: boolean;
  collectibleDetails: boolean;
  marketplaceListing: boolean;
  events: boolean;
}

interface ISubgraphConfig {
  endpoints: { [key in NetworkId]?: string };
  modules: ISubgraphConfigModules;
}

const getURI = (networkid: number) => {
  if (
    _app_configuration?.nodeProviders &&
    _app_configuration?.nodeProviders[networkid]
  ) {
    return _app_configuration?.nodeProviders[networkid];
  } else {
    return nodeProviders[networkid];
  }
};

const NewForwarderCompatibility: Record<NetworkId, boolean> = {
  [NetworkId.INVALID_CHAIN]: false,
  [NetworkId.ARBITRUM_SEPOLIA]: true,
  [NetworkId.ETHEREUM_SEPOLIA]: true,
  [NetworkId.STAGING_ARBITRUM_NOVA]: false,
  [NetworkId.ARBITRUM]: true,
  [NetworkId.ARBITRUM_NOVA]: false,
  [NetworkId.MAINNET]: false,
  [NetworkId.POLYGON_MAINNET]: false,
};

const GasMultiplier: Record<NetworkId, number> = {
  [NetworkId.INVALID_CHAIN]: 1,
  [NetworkId.ARBITRUM_SEPOLIA]: 1.25,
  [NetworkId.ETHEREUM_SEPOLIA]: 1,
  [NetworkId.STAGING_ARBITRUM_NOVA]: 1,
  [NetworkId.ARBITRUM]: 1.25,
  [NetworkId.ARBITRUM_NOVA]: 1,
  [NetworkId.MAINNET]: 1,
  [NetworkId.POLYGON_MAINNET]: 1,
};

export const getGasMultiplier = (networkid: NetworkId): number => {
  if (_app_configuration?.GasMultiplier) {
    return (
      _app_configuration?.GasMultiplier?.[networkid] ??
      GasMultiplier?.[networkid] ??
      1
    );
  } else {
    return GasMultiplier?.[networkid] ?? 1;
  }
};

export const checkNewForwarderCompatibility = (
  networkid: NetworkId,
): boolean => {
  if (!Boolean(addresses?.[networkid]?.Loot8Forwarder)) {
    return false;
  }

  if (_app_configuration?.NewForwarderCompatibility) {
    return (
      _app_configuration?.NewForwarderCompatibility?.[networkid] ??
      NewForwarderCompatibility[networkid] ??
      false
    );
  } else {
    return NewForwarderCompatibility[networkid] ?? false;
  }
};

const subgraphs = {
  '1': '',
  '137': '',
  '42161': 'https://graph-node-01.loot8-services.dev:8000/subgraphs/name/loot8',
  '42170': '',
  '421614': 'https://ipfs-dev-02.loot8-services.dev:8000/subgraphs/name/loot8',
  '11155111':
    'https://ipfs-dev-02.loot8-services.dev:8000/subgraphs/name/loot8-eth-sepolia',
  '42170999': '',
};

const getSubgraphConfigModules = (
  appconfig: any,
): ISubgraphConfig['modules'] => {
  return {
    user: appconfig?.subgraphService?.modules?.user ?? true,
    userOwnedCollections:
      appconfig?.subgraphService?.modules?.userOwnedCollections ?? true,
    whitelistedCollections:
      appconfig?.subgraphService?.modules?.whitelistedCollections ?? true,
    entity: appconfig?.subgraphService?.modules?.entity ?? true,
    listTokenOwner: appconfig?.subgraphService?.modules?.listTokenOwner ?? true,
    collectibleDetails:
      appconfig?.subgraphService?.modules?.collectibleDetails ?? true,
    marketplaceListing:
      appconfig?.subgraphService?.modules?.marketplaceListing ?? true,
    events: appconfig?.subgraphService?.modules?.events ?? true,
  };
};

const getParsedSubgraphConfig = (appconfig: any, chainIds: number[]) => {
  const modules = getSubgraphConfigModules(appconfig);
  const endpoints: ISubgraphConfig['endpoints'] = {};

  chainIds.forEach(chainId => {
    endpoints[chainId] =
      appconfig?.subgraphService?.endpoints?.[chainId] ||
      subgraphs[chainId] ||
      '';
  });

  return { endpoints, modules };
};

export const getSubgraphConfig = async () => {
  let appconfig;

  try {
    appconfig = await getAppConfiguration();
  } catch (error) {
    LogToLoot8Console(
      'getSubgraphConfig | Error while reading app config, fallback triggered',
    );
  }

  let config: ISubgraphConfig = {
    endpoints: {},
    modules: getSubgraphConfigModules(undefined),
  };

  //* Provide the Config from the IPNS App Config
  switch (_env) {
    case AppEnvironment.DEVELOPMENT: {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.ARBITRUM_SEPOLIA,
        NetworkId.ETHEREUM_SEPOLIA,
      ]);
      break;
    }
    case AppEnvironment.STAGING: {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.STAGING_ARBITRUM_NOVA,
      ]);
      break;
    }
    case AppEnvironment.PRODUCTION: {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.MAINNET,
        NetworkId.POLYGON_MAINNET,
        NetworkId.ARBITRUM,
        NetworkId.ARBITRUM_NOVA,
      ]);
      break;
    }
    default: {
      config = getParsedSubgraphConfig(appconfig, [
        NetworkId.ARBITRUM_SEPOLIA,
        NetworkId.ETHEREUM_SEPOLIA,
      ]);
      break;
    }
  }

  return config;
};

const getLogsProviderURI = (networkid: number) => {
  if (
    _app_configuration?.logsNodeProviders &&
    _app_configuration?.logsNodeProviders[networkid]
  ) {
    return _app_configuration?.logsNodeProviders[networkid];
  } else {
    return logsNodeProviders[networkid];
  }
};

export const getNetwork = () => {
  return Number(Constants.expoConfig.extra.NETWORK_ID);
};

export const getOldNetwork = () => {
  return Number(Constants.expoConfig.extra.OLD_NETWORK_ID);
};

export const IsLogEnable = () => {
  return Constants.expoConfig.extra.SENTRY_LOG == 'true';
};

export const slowProcessCollectionOnWeb = () => {
  return Constants.expoConfig.extra.SLOW_PROCESS_COLLECTION_ON_WEB == 'true';
};

export const gasEstimationEnabled = () => {
  return Constants.expoConfig.extra.NEED_GAS_ESTIMATION == 'true';
};
export const getNetworkName = () => {
  return NETWORKS[getNetwork()].chainName;
};
export const getNetworkRPC = () => {
  return NETWORKS[getNetwork()].rpcUrls[0];
};
export const getNetworkURI = () => {
  return NETWORKS[getNetwork()].uri();
};

export const getEtherScanProvider = () => {
  return new EtherscanProvider(getNetwork());
};

export const getAnynetEtherScanProvider = (chainId: NetworkId) => {
  return new EtherscanProvider(chainId);
};

let _staticProvider = {};
export const getStaticProvider = () => {
  const uri = NETWORKS[getNetwork()].uri();
  if (!_staticProvider[uri]) {
    _staticProvider[uri] = new StaticJsonRpcProvider(uri);
  }
  return _staticProvider[uri];
};

let _anynetStaticProvider = {};
export const getAnynetStaticProvider = (chainId: NetworkId) => {
  if (!_anynetStaticProvider[chainId]) {
    _anynetStaticProvider[chainId] = new StaticJsonRpcProvider(
      NETWORKS[chainId]?.uri(),
    );
  }
  return _anynetStaticProvider[chainId];
};

let _staticLogsProvider = {};
export const getStaticLogsProvider = (chainId: NetworkId) => {
  const uri = getLogsProviderURI(chainId);
  if (!_staticLogsProvider[chainId]) {
    _staticLogsProvider[chainId] = new StaticJsonRpcProvider(uri);
  }
  return _staticLogsProvider[chainId];
};

export const getNetworkID = (chain: number) => {
  let selectedNetworkID: NetworkId;

  switch (_env) {
    case AppEnvironment.DEVELOPMENT: {
      switch (chain) {
        case 421614:
          selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
          break;
        case 11155111:
          selectedNetworkID = NetworkId.ETHEREUM_SEPOLIA;
          break;
        default:
          selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
          break;
      }
      break;
    }

    case AppEnvironment.STAGING: {
      switch (chain) {
        case 42170999:
          selectedNetworkID = NetworkId.STAGING_ARBITRUM_NOVA;
          break;
        default:
          selectedNetworkID = NetworkId.STAGING_ARBITRUM_NOVA;
          break;
      }
      break;
    }

    case AppEnvironment.PRODUCTION: {
      switch (chain) {
        case 137:
          selectedNetworkID = NetworkId.POLYGON_MAINNET;
          break;
        case 42161:
          selectedNetworkID = NetworkId.ARBITRUM;
          break;
        case 1:
          selectedNetworkID = NetworkId.MAINNET;
          break;
        case 42170:
          selectedNetworkID = NetworkId.ARBITRUM_NOVA;
          break;
        default:
          selectedNetworkID = NetworkId.ARBITRUM_NOVA;
          break;
      }
      break;
    }

    default: {
      selectedNetworkID = NetworkId.ARBITRUM_SEPOLIA;
      break;
    }
  }

  return selectedNetworkID;
};

export const WEBHOOK_URL = (networkId: number) => {
  if (networkId && _relayerInfo) {
    if (!Boolean(NETWORKS[networkId].chainId)) {
      return '';
    }

    const relayer = _relayerInfo?.find(x => x.networkId === networkId);
    if (relayer && relayer.url) {
      return relayer.url;
    }
  }
  return NETWORKS[networkId].WEBHOOK_URL;
};

export const getGenesisBlockNumber = (networkId: number): number => {
  if (_app_configuration?.genesisBlockNumber) {
    return _app_configuration?.genesisBlockNumber[networkId];
  } else {
    return NETWORKS[networkId].genesisBlockNumber;
  }
};

// Define fallback network used across all environments,
// Empty to prevent major exceptions for e.g NETWORKS[invalid_chain].WEBHOOK_URL
const FALLBACK_NETWORK = {
  chainName: '',
  chainId: 0,
  nativeCurrency: {
    name: '',
    symbol: '',
    decimals: 18,
  },
  rpcUrls: [''],
  blockExplorerUrl: '',
  image: undefined,
  imageAltText: '',
  uri: () => '',
  WEBHOOK_URL: '',
  genesisBlockNumber: 0,
};

export const NETWORKS_BY_ENV = {
  [AppEnvironment.DEVELOPMENT]: {
    [NetworkId.ARBITRUM_SEPOLIA]: {
      chainName: 'Arbitrum Sepolia',
      chainId: NetworkId.ARBITRUM_SEPOLIA,
      nativeCurrency: {
        name: 'Sepolia ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
      ],
      blockExplorerUrl: 'https://sepolia-explorer.arbitrum.io/',
      image: arbitrum_logo,
      imageAltText: 'Arbitrum Sepolia',
      uri: () => getURI(NetworkId.ARBITRUM_SEPOLIA),
      WEBHOOK_URL:
        'https://loot8-relayer-dev.loot8-services.dev/webhook/aaae77ec-0fee-4075-9335-bc1010129632',
      genesisBlockNumber: 600000,
    },
    [NetworkId.ETHEREUM_SEPOLIA]: {
      chainName: 'Ethereum Sepolia',
      chainId: NetworkId.ETHEREUM_SEPOLIA,
      nativeCurrency: {
        name: 'Sepolia ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/11155111/f3w21nwivc6b2b388lcaatyzhkk9sd61',
      ],
      blockExplorerUrl: 'https://sepolia.etherscan.io/',
      image: ethereum_logo,
      imageAltText: 'Ethereum Sepolia',
      uri: () => getURI(NetworkId.ETHEREUM_SEPOLIA),
      WEBHOOK_URL:
        'https://loot8-relayer-qa.loot8-services.dev/webhook/f690f9fd-4801-4141-b552-c58853a2121a',
      genesisBlockNumber: 600000,
    },
  },
  [AppEnvironment.STAGING]: {
    [NetworkId.STAGING_ARBITRUM_NOVA]: {
      chainName: 'Arbitrum Nova',
      chainId: NetworkId.STAGING_ARBITRUM_NOVA,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
      ],
      blockExplorerUrl: 'https://nova.arbiscan.io/',
      image: arbitrum_logo,
      imageAltText: 'Staging Arbitrum Nova',
      uri: () => getURI(NetworkId.STAGING_ARBITRUM_NOVA),
      WEBHOOK_URL:
        'https://loot8-relayer-qa.loot8-services.dev/webhook/257933aa-84ee-4de4-8007-231e4d38f8eb',
      genesisBlockNumber: 27000000,
    },
  },
  [AppEnvironment.PRODUCTION]: {
    [NetworkId.ARBITRUM]: {
      chainName: 'Arbitrum',
      chainId: NetworkId.ARBITRUM,
      nativeCurrency: {
        name: 'Arbitrum',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
      ],
      blockExplorerUrl: 'https://arbiscan.io',
      image: arbitrum_logo,
      imageAltText: 'Arbitrum Mainnet',
      uri: () => getURI(NetworkId.ARBITRUM),
      WEBHOOK_URL:
        'https://loot8-relayer.loot8-services.dev/webhook/1211cbcc-aec4-4787-979b-048a49339f40',
      genesisBlockNumber: 70095541,
    },
    [NetworkId.POLYGON_MAINNET]: {
      chainName: 'Polygon Mainnet',
      chainId: NetworkId.POLYGON_MAINNET,
      nativeCurrency: {
        name: 'Polygon',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav',
      ],
      blockExplorerUrl: 'https://polygonscan.com/',
      image: polygon_matic,
      imageAltText: 'Polygon Mainnet',
      uri: () => getURI(NetworkId.POLYGON_MAINNET),
      WEBHOOK_URL:
        'https://loot8-relayer.loot8-services.dev/webhook/b7871c16-35e7-4171-b538-63c2cb66f025',
      genesisBlockNumber: 38000000,
    },
    [NetworkId.MAINNET]: {
      chainName: 'ETH Mainnet',
      chainId: NetworkId.MAINNET,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://app.loot8.io/rpc/1/fmyholol0tax4pugrprbk3dacb2nmdav'],
      blockExplorerUrl: 'https://etherscan.io/',
      image: null,
      imageAltText: 'Eth Mainnet',
      uri: () => getURI(NetworkId.MAINNET),
      WEBHOOK_URL: '',
      genesisBlockNumber: 17666174,
    },

    [NetworkId.ARBITRUM_NOVA]: {
      chainName: 'Arbitrum Nova',
      chainId: NetworkId.ARBITRUM_NOVA,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [
        'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
      ],
      blockExplorerUrl: 'https://nova.arbiscan.io/',
      image: arbitrum_logo,
      imageAltText: 'Arbitrum Nova',
      uri: () => getURI(NetworkId.ARBITRUM_NOVA),
      WEBHOOK_URL:
        'https://loot8-relayer.loot8-services.dev/webhook/0e7a2121-ab76-4a32-b01d-ed98788be7f3',
      genesisBlockNumber: 27000000,
    },
  },
};

export const NETWORKS: { [key: number]: INetwork } = new Proxy(
  Object.keys(NETWORKS_BY_ENV[_env] || {}).reduce(
    (acc, chainId) => ({
      ...acc,
      [chainId]: {
        ...FALLBACK_NETWORK,
        ...NETWORKS_BY_ENV[_env][chainId],
      },
    }),
    {},
  ),
  {
    get(target, chainId: string) {
      return target[chainId] || FALLBACK_NETWORK;
    },
  },
);

export const CategoriesList = [
  {
    id: Category.OTHER,
    name: 'Other',
  },
  {
    id: Category.SPORTS,
    name: 'Sports',
  },
  {
    id: Category.MUSIC,
    name: 'Music',
  },
  {
    id: Category.CELEBRITIES,
    name: 'Celebrities',
  },
  {
    id: Category.EDUCATORS,
    name: 'Educators',
  },
  {
    id: Category.BUSINESS,
    name: 'Business',
  },
  {
    id: Category.GAMING,
    name: 'Gaming',
  },
  {
    id: Category.ARTIST,
    name: 'Artist',
  },
  {
    id: Category.FOUNDATIONS,
    name: 'Foundations',
  },
];

// app storage key
export const APP_VERSION = '@loot8_version';
export const APP_STORAGE_USER_KEY = '@user_key';
export const APP_STORAGE_USER_ADDRESS = '@user_address';
export const APP_STORAGE_NETWORKID = '@networkid';
export const APP_STORAGE_USER_NONCE = '@user_nonce';
export const NOTIFICATIONS = '@notificationsV1_';
export const APP_STORAGE_ALL_PASSPORTDETAILS = '@allpassportdetails';

export const APP_STORAGE_ENTITY_LIST = '@allentitylist';
export const APP_STORAGE_PASSPORT_LIST = '@allpassportlist';
export const APP_STORAGE_OFFER_LIST = '@allofferlist';
export const APP_STORAGE_EVENT_LIST = '@alleventlist';
export const APP_STORAGE_THIRDPARTY_LIST = '@allThirdPartylist';
export const APP_STORAGE_DIGITALCOLLECTIBLE_LIST =
  '@alldigitalcollectiablelist';
export const APP_STORAGE_USER_THIRDPARTY_LIST =
  '@alluserdigitalcollectiablelist';
export const APP_STORAGE_COLLECTIBLE_LIST = '@allcollectiblelist';
export const APP_STORAGE_SORTED_COLLECTION_LIST = '@loot8SortedCollectionList';
export const USER_WALKTHROUGH_STATUS = '@userWalkthroughStatus';
export const APP_STORAGE_TX_TIMESTAMPS = '@allTxTimestamps';
export const SHOW_APP_SELECTION = '@showAppSelection';
export const SELECTED_APP = '@selectedApp';

export const APP_STORAGE_GET_ENTITY_OFFER_LIST = entityaddress => {
  return '@entityoffer_' + entityaddress.toLowerCase();
};
export const APP_STORAGE_GET_PASSPORT_THIRDPARTY_LIST = passportAddress => {
  return '@passportthirdparty_' + passportAddress.toLowerCase();
};
export const APP_STORAGE_GET_COLLECTIBLEDETAILS = collectibleAddress => {
  return '@collectible_' + collectibleAddress.toLowerCase();
};
export const APP_STORAGE_GET_PASSPORTBALANCE = passportaddress => {
  return '@passportbalance_' + passportaddress.toLowerCase();
};
export const APP_STORAGE_GET_FRIENDS = useraddress => {
  return '@friends_' + useraddress.toLowerCase();
};
export const APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS = (
  collectiableAddress,
  chainID,
) => {
  return '@thirdparty_' + chainID + '_' + collectiableAddress.toLowerCase();
};
export const APP_STORAGE_GET_PATRONCOLLECTDETAILS = (
  collectiableType,
  address,
) => {
  return (
    '@patroncollectiblelist_' + collectiableType + '_' + address.toLowerCase()
  );
};
export const APP_STORAGE_GET_ALL_MINTED_PATRONCOLLECTDETAILS =
  collectiableType => {
    return '@patroncollectiblelist_' + collectiableType;
  };

export const APP_ALL_TRANSACTION = '@alltransaction';

export const initialBlock = 3607057;
export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
export const EXPO_LOOT8_PROJECT_ID = 'b5788999-ddd8-43a7-8c65-102469f18ee8';

export const LAST_BLOCK_NUMBER = '@lastBlockNumber';
export const LAST_TIMESTAMP = '@lastTimeStamp';
export const PRIVATE_MESSAGE_FRIEND_REQUESTS = '@loot8-v1-pm-friend-requests';

export const LOCATION_API_TASK = 'location-api-task';
export const PASSPORT_LIFE_SPAN_KEY = '@passport_life_span_key';
export const PASSPORT_STORAGE_AGE = 5; //minutes

export const EST_PORTAL_MASTER_KEY =
  Constants.expoConfig.extra.EST_PORTAL_MASTER_KEY;
export const MASTER_KEY = Constants.expoConfig.extra.MASTER_KEY;
export const WEB_REDIRECT_URL = Constants.expoConfig.extra.WEB_REDIRECT_URL;

export const APP_CONFIGURATION_FILE = 'appconfig.json';

export const PASSPORT_MESSAGES = passportAddress =>
  '@loot8-v1-passport-messages_' + passportAddress;
export const PASSPORT_MESSAGES_LAST_READ = (
  passportAddress: string,
  userAddress: string,
) => {
  return (
    '@loot8-v1-pm-lastReadTimestamp_' +
    passportAddress.substring(0, 7) +
    (userAddress ? '_' + userAddress.substring(0, 7) : '')
  );
};
export const PASSPORT_MESSAGES_LAST_FETCH = passportAddress =>
  '@loot8-v1-pm-lastFetchTimestamp_' + passportAddress;
export const PASSPORT_MESSAGES_LAST_EXECUTION_TIME = passportAddress =>
  '@loot8-v1-pm-lastExecutionTime_' + passportAddress;

export const PASSPORT_CACHE_LAST_FETCH = passportAddress =>
  '@loot8-v1-passportRefresh_' + passportAddress;

export const TABLET_DIMENTION = { width: 768, height: 1024 };

export const LOOT8_FEED = 'loot8-feed-';
export const SOCIAL_MEDIA_SIGNER =
  Constants.expoConfig.extra.SOCIAL_MEDIA_SIGNER.toLowerCase();

export const MUTUALFRIENDS_MESSAGES_LAST_READ = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-mfm-lastReadTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const FRIEND_PRIVATE_MESSAGES_LAST_READ = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-friend-pm-lastReadTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const FRIEND_PRIVATE_MESSAGES_LAST_REQSENT = (
  userAddress: string,
  friendAddress: string,
) => {
  return (
    '@loot8-v1-friend-pm-lastReqSentTimestamp_' +
    userAddress.substring(0, 7) +
    (friendAddress ? '_' + friendAddress.substring(0, 7) : '')
  );
};

export const MIGRATION_STATUS = '@loot8-migration-status';
// load app configuration
const loadAppConfiguration = async () => {
  try {
    const response = await fetch(
      IPNS_URL() +
        Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY.replace(
          'ipns://',
          '',
        ) +
        '/' +
        APP_CONFIGURATION_FILE,
      { method: 'GET', headers: { 'Content-Type': 'application/text' } },
    );

    if (response.status === 200) {
      const configData = await response.text();
      if (configData) {
        _app_configuration = overrideAppConfig(
          JSON.parse(configData),
          Constants.expoConfig.extra,
        );
        return _app_configuration;
      }
    }
  } catch (err) {
    LogToLoot8Console('load configuration failed', err);
  }
};

// get api request timeout
export const API_REQUEST_TIMEOUT = () => {
  if (_app_configuration && _app_configuration.apiRequestTimeout) {
    _api_request_timeout = _app_configuration.apiRequestTimeout;
  }
  return _api_request_timeout;
};

export const MAX_DATE_TIME = 8640000000000000; // Sat Sep 13 275760 05:30:00 GMT+0530
export const MIN_DATE_TIME = -2209008070000; // Mon Jan 01 1900 00:00:00 GMT+0521
export const PLAYSTORE_URL =
  'https://play.google.com/store/apps/details?id=com.loot8.loot8_app';
export const APPSTORE_URL = 'https://apps.apple.com/app/loot8/id1666456551';
export const PLAYSTORE_APP_URL = 'market://details?id=com.loot8.loot8_app';
export const APPSTORE_APP_URL =
  'itms-apps://apps.apple.com/id/app/loot8/id1666456551';
export const DISCORD_APPSTORE_URL =
  'https://apps.apple.com/app/discord-chat-talk-hangout/id985746746';
export const TELEGRAM_APPSTORE_URL =
  'https://apps.apple.com/app/telegram-messenger/id686449807';
export const TIKTOK_APPSTORE_URL =
  'https://apps.apple.com/us/app/tiktok/id835599320';
export const INSTAGRAM_APPSTORE_URL =
  'https://apps.apple.com/us/app/instagram/id389801252';
export const FACEBOOK_APPSTORE_URL =
  'https://apps.apple.com/us/app/facebook/id284882215';
export const TWITTER_APPSTORE_URL =
  'https://apps.apple.com/us/app/twitter/id1482454543';
export const TWITCH_LOGIN_VERIFIER_NAME = 'loot8-custom-auth-twitch';
export const TWITCH_CLIENT_ID = 'ujxg80skt89ijb4r699kju59eb21f5';

// Batchsize of requests/network calls
export const NETWORK_CALL_BATCHSIZE = 10;

// Expiry Time for stored timestamps - Cashout Loot8 Tokens - 7 Days
export const CASHOUT_TX_TIMESTAMP_EXPIRY_IN_MS = 7 * 24 * 60 * 60 * 1000;

// Estimated completion time of Tx - Cashout Loot8 Tokens - 1 hour
export const CASHOUT_EST_COMPLETION_TIME_IN_SECS = 60 * 60;

// Calculate Deadline used by LootForwarder - Now + 5 minutes
export const META_TX_DEADLINE = (): number => {
  // Deadline used by LootForwarder while executing a MetaTx - 5 minutes
  let time = _app_configuration?.META_TX_DEADLINE_IN_SECONDS
    ? _app_configuration?.META_TX_DEADLINE_IN_SECONDS
    : 5 * 60;

  return Math.round(Date.now() / 1000) + time;
};

export const getAppConfiguration = async () => {
  try {
    if (_app_configuration) {
      return _app_configuration;
    } else {
      initAppConfig();
      await loadAppConfiguration();
      return _app_configuration;
    }
  } catch (e) {
    LogToLoot8Console('getAppConfiguration - app configuration failed', e);
  } finally {
    return _app_configuration;
  }
};

// initialize app configuration.
const overrideAppConfig = (config, extra): any => {
  try {
    // get relayer detail.
    if (config && config?.relayerInfo && config?.relayerInfo.length > 0) {
      _relayerInfo = config?.relayerInfo;
    } else {
      _relayerInfo = null;
    }

    LogToLoot8Console('CONFIG_ENVIRONMENT', extra.CONFIG_ENVIRONMENT);
    //setting node providers
    if (
      extra.CONFIG_ENVIRONMENT === 'local' &&
      Constants.expoConfig.extra.LOCAL_APP_CONFIG
    ) {
      const localConfig = JSON.parse(
        Constants.expoConfig.extra.LOCAL_APP_CONFIG,
      );
      try {
        config = { ...config, ...localConfig };
      } catch (error) {
        console.error('Error while parsing local config');
      }
    }
  } catch (error) {
    LogToLoot8Console('initializeAppConfiguration:', error);
  }

  return config;
};

export const IsLoot8ConsoleLogEnable = () => {
  return Constants.expoConfig.extra.LOOT8_CONSOLE_LOG == 'true';
};

// map data cache key
export const APP_MAP_COLLECTIBLE_CONSTRAINT = collectibleAddress => {
  return '@map_constraint_' + collectibleAddress.toLowerCase();
};

const initAppConfig = () => {
  _app_configuration = {
    nodeProviders,
    logsNodeProviders,
  };
};

let nodeProviders = {
  '42161': 'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
  '137': 'https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav',
  '1': 'https://mainnet.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '421614': 'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '11155111':
    'https://app.loot8.io/rpc/11155111/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '42170': 'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
  '42170999':
    'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
};

let logsNodeProviders = {
  '42161': 'https://app.loot8.io/rpc/42161/fmyholol0tax4pugrprbk3dacb2nmdav',
  '137': 'https://app.loot8.io/rpc/137/fmyholol0tax4pugrprbk3dacb2nmdav',
  '1': 'https://mainnet.infura.io/v3/ca086b5ab8cd4572b6e72621e836363f',
  '421614': 'https://app.loot8.io/rpc/421614/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '11155111':
    'https://app.loot8.io/rpc/11155111/f3w21nwivc6b2b388lcaatyzhkk9sd61',
  '42170': 'https://app.loot8.io/rpc/42170/fmyholol0tax4pugrprbk3dacb2nmdav',
  '42170999':
    'https://app.loot8.io/rpc/staging/42170999/femvikz02ltxdhbw78oclxt2rbgvx5by',
};

export const enum SortingOptions {
  NONE = 0,
  ALPHABETICAL_ASC = 1,
  ALPHABETICAL_DESC = 2,
  COST_ASC = 3,
  COST_DESC = 4,
}

export const isNativeChain = (chainId: number) => {
  return (
    chainId == NetworkId.ARBITRUM_SEPOLIA ||
    chainId == NetworkId.ETHEREUM_SEPOLIA ||
    chainId == NetworkId.ARBITRUM_NOVA ||
    chainId == NetworkId.ARBITRUM ||
    chainId == NetworkId.STAGING_ARBITRUM_NOVA
  );
};

/**
 * Determines the concurrent request limit based on the current queue length.
 * Larger queues indicate network congestion, so we reduce the request size.
 * Smaller queues allow for larger concurrent request sizes to ensure speed.
 *
 * @param queueLength - The current length of the queue.
 * @returns The appropriate concurrent request limit.
 */
export const getConcurrentRequestLimit = (queueLength: number): number => {
  if (queueLength > 150) return 5;
  if (queueLength > 100) return 10;
  if (queueLength > 50) return 20;
  if (queueLength > 20) return 30;
  return 60;
};

let gifFileList: string[] = [];
export const pushToGIFFileList = (item: string | Array<any>) => {
  if (Array.isArray(item)) {
    gifFileList = item;
  } else {
    gifFileList.push(item);
  }
};
export const removeFromGIFFileList = (item: string) => {
  gifFileList = gifFileList.filter(
    p => p?.toLowerCase() !== item?.toLowerCase(),
  );
};
export const getGIFFileList = () => gifFileList;

export const imagesDir = `${FileSystem.documentDirectory}loot8-images/`;
export const thumbnailImagesDir = `${imagesDir}thumbnails/`;
export const optimizedImagesDir = `${imagesDir}optimized/`;
export const gifImageListFile = `${imagesDir}gifs.txt`;

// Checks if gif directory exists. If not, creates it
export const ensureDirExists = async () => {
  let config = await getAppConfiguration();
  if (config && config?.cacheImageEnabled) {
    const dirInfo = await FileSystem.getInfoAsync(imagesDir);
    if (!dirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(imagesDir, { intermediates: true });
    }
    const thumbnailDirInfo = await FileSystem.getInfoAsync(thumbnailImagesDir);
    if (!thumbnailDirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(thumbnailImagesDir, {
        intermediates: true,
      });
    }
    const optimizedDirInfo = await FileSystem.getInfoAsync(optimizedImagesDir);
    if (!optimizedDirInfo.exists) {
      LogToLoot8Console("Cache Images directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(optimizedImagesDir, {
        intermediates: true,
      });
    }
  }
};

export const MINTED_COLLECTION = collectionAddress =>
  '@minted_collection_' + collectionAddress;
